/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { Query } from '@apollo/client/react/components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { gql } from '@apollo/client';
import { object, shape, string } from 'prop-types';
import mbpLogger from 'mbp-logger';
import { compose } from 'recompose';
import SeoArticlesBlock from './SeoArticlesBlock';
import { GRAPHQL_ENV } from '../../../gql';
import * as app from '../../../../state/ducks/App';
import withPageView from '../../../helpers/tracking/hocs/withPageView';
import { coreBrowsingPageViewSuccessTest } from '../../../helpers/tracking/common/commonTrackingHelpers';

const brandSelectors = app?.ducks?.brand?.selectors;

const SeoArticlesPage = ({ brand, page }) => {
    const Seo_Articles_PAGE_QUERY = gql`
            query SeoArticlesPageQuery {
                findContentPage(brand: "${brand['domain-name']}", url: "${page?.path}", contentType: "${page?.contentType}", environment: "${GRAPHQL_ENV}", locale: "en-us") {
                    content
                }
            }
        `;

    return (
        <Query query={Seo_Articles_PAGE_QUERY}>
            {({ loading, error, data }) => {
                if (loading) {
                    return  <div style={{ height: '80vh' }} />; // prevent header and footer from snapping together
                }

                if (error) {
                    mbpLogger.logError({
                        appName: process.env.npm_package_name,
                        query: Seo_Articles_PAGE_QUERY,
                        component: 'SeoArticlesPage.js',
                        message: 'Error loading data from Graphql',
                        env: GRAPHQL_ENV,
                        error,
                    });
                    return null;
                }

                if (!data) {
                    mbpLogger.logWarning({
                        appName: process.env.npm_package_name,
                        query: Seo_Articles_PAGE_QUERY,
                        component: 'SeoArticlesPage.js',
                        message: 'No data returned for query',
                        env: GRAPHQL_ENV,
                    });
                    return null;
                }

                const blocks = data?.findContentPage?.content?.entries[0] || null;
                if (blocks !== null) {
                    return (
                        <>
                            <SeoArticlesBlock data={blocks} />
                        </>
                    );
                }
                return null;
            }}
        </Query>
    );
};

SeoArticlesPage.propTypes = {
    brand: object.isRequired,
    page: shape({
        contentType: string.isRequired,
        path: string.isRequired,
    }).isRequired,
};
const mapStateToProps = (state) => ({
    brand: brandSelectors?.getBrand(state),
});

const enhance = compose(
    withPageView({
        pageViewSuccessTest: coreBrowsingPageViewSuccessTest,
    }),
    withRouter,
    connect(mapStateToProps),
);

export default enhance(SeoArticlesPage);
