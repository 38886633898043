/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    object,
    shape,
    arrayOf,
    string,
} from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { uid } from 'react-uid';
import { Link } from 'react-router-dom';

const styles = (theme) => ({
    container: {
        maxWidth: '1440px',
        margin: '0 auto 4% auto',
        display: 'flex',
        fontSize: '16px',
        fontWeight: '500',
        boxSizing: 'content-box',
        backgroundPosition: 'center',
    },
    content: {
        maxWidth: '1184px',
        margin: '0 auto',
        padding: '5%',
        [theme.breakpoints.down('sm')]: {
            padding: '7%',
        },
        '& h1': {
            margin: '10px 0',
            fontSize: '48.4px',
            color: theme.palette.cms?.primary || theme.palette.colorPrimary,
            fontWeight: '400',
            '& sup': {
                fontSize: 'x-large',
            },
        },
    },
    main: {
        backgroundColor: 'rgba(255,255,255,0.90)',
        borderRadius: '30px',
        margin: '0 auto',
        padding: '5%',
        maxWidth: 'inherit',
    },
    smartGiftHeader: {
        marginBottom: '4%',
        '& p:nth-of-type(1)': {
            fontSize: '24px',
            color: '#1b373f',
        },
        '& p': {
            marginBottom: '1.5%',
        },
        '& div': {
            width: '100%',
            display: 'flex',
            lineHeight: '1',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            '& > span': {
                flexGrow: '0',
                display: 'inline-block',
                fontSize: '34px',
                fontFamily: '\'Roboto Slab\', serif',
                margin: '0 1% 0 0',
                color: theme.palette.cms?.primary || theme.palette.colorPrimary,
                [theme.breakpoints.down('sm')]: {
                    fontSize: 30,
                    margin: '10px 0px',
                    textAlign: 'center',
                },
            },
            '& > img#smile-icon': {
                maxWidth: '42px',
                height: 'auto',
                flexGrow: '0',
                display: 'inline-block',
            },
        },
    },
    flexList: {
        margin: '1em',
        overflow: 'hidden',
        display: 'block',
        '& ul': {
            listStyleType: 'none',
        },
        '& ul#articles': {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginLeft: '-1px',
            overflow: 'hidden',
            margin: '0',
            padding: '0',
            [theme.breakpoints.down('sm')]: {
                display: 'block',
            },
            '& li': {
                display: 'flex',
                flex: '0 1 45%',
                textAlign: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                position: 'relative',
                margin: '0 3%',
                whiteSpace: 'pre-wrap',
                '& p:nth-of-type(2)': {
                    margin: '10px 0',
                },
                '&:nth-of-type(1) p': {
                    width: '68%',
                },
                '&:nth-of-type(2) p': {
                    width: '75%',
                },
                '&:nth-of-type(3) p': {
                    width: '90%',
                },
                '&::after': {
                    content: '\'\\279d\'',
                    margin: '0 0.6em',
                    color: '#d2d2d2',
                    position: 'absolute',
                    top: '-3%',
                    right: '-33%',
                    fontSize: '54.352px',
                    [theme.breakpoints.down('sm')]: {
                        dispaly: 'none',
                    },
                },
                '&:nth-of-type(3)::after': {
                    content: 'none',
                },
                '& p': {
                    lineHeight: 1.2,
                },
            },
        },
    },
    stepNumberIcon: {
        maxWidth: '100%',
        height: 'auto',
    },
    stepTitle: {
        fontSize: '22px',
        marginLeft: 5,
        lineHeight: 1,
        textAlign: 'left',
    },
    stepDescription: {
        width: '49.5%',
        fontSize: '18px',
    },
    centerText: {
        textAlign: 'center',
        margin: '0 auto',
        lineHeight: '1',
        fontSize: 16,
    },
    ctaBtn: {
        width: '35%',
        maxWidth: '486px',
        maxHeight: '70px',
        lineHeight: '40px',
        fontSize: '18px',
        fontWeight: 'bold',
        backgroundColor: theme.palette.cms?.primary || theme.palette.colorPrimary,
        color: theme.palette.cms?.white || '#ffffff',
        textTransform: 'uppercase',
        textDecoration: 'none',
        letterSpacing: '1px',
        margin: '0 auto',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '1.5%',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            width: '90%',
            fontSize: '0.9em',
        },
    },
    imageBlock: {
        display: 'flex',
        alignItems: 'center',
    },
});

const SmartGiftBlock = ({
    classes,
    data,
}) => {
    if (data) {
        const {
            article_header,
            overview, hero,
            related_articles,
        } = data;
        const { hero_image_desktop, link } = hero;
        const ctaStyle = {};
        const overviewStyle = {};
        ctaStyle.color = hero?.cta_text_color?.color || '';
        ctaStyle.background = hero?.cta_background_color?.color || '';
        overviewStyle.color = data?.overview_copy_color?.color || '';
        return (
            <div style={{ backgroundImage: `url(${hero_image_desktop.url}?height=900&width=1440)` }} className={classes.container}>
                <div className={classes.content}>
                    <div className={classes.main}>
                        <div className={classes.smartGiftHeader}>
                            {article_header && <h3 className={classes.centerText}>{article_header}</h3>}
                            <div>
                                <span style={overviewStyle}>{overview}<sup>™</sup></span>
                            </div>
                        </div>
                        <div className={classes.flexList}>
                            <ul id="articles">
                                {related_articles.articles.map((article) => {
                                    const { description, image, link: stepTitle } = article;
                                    return (
                                        <li key={uid(article)}>
                                            <div className={classes.imageBlock}> <img className={classes.stepNumberIcon} src={ReactHtmlParser(image.url)} alt="Step Number" />
                                                <span className={classes.stepTitle}><strong>{ReactHtmlParser(stepTitle.title)}</strong></span>
                                            </div>
                                            <p className={classes.stepDescription}>{ReactHtmlParser(description)}</p>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                        <Link style={ctaStyle} className={classes.ctaBtn} title={link?.title} to={link?.href}>{link?.title}</Link>
                    </div>
                </div>
            </div>
        );
    }

    return null;
};

SmartGiftBlock.propTypes = {
    classes: object.isRequired,
    data: shape({
        article_header: string,
        overview: string,
        hero: shape({
            hero_image_desktop: shape({
                url: string,
            }),
            link: shape({
                title: string,
                href: string,
            }),
        }),
        related_articles: shape({
            articles: arrayOf(shape({
                description: string,
                image: shape({
                    url: string,
                }),
                link: shape({
                    title: string,
                    url: string,
                }),
            })),
        }),
    }),
};

SmartGiftBlock.defaultProps = {
    data: {},
};

export default withStyles(styles)(SmartGiftBlock);
