/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    object, shape, string, bool,
} from 'prop-types';
import { useUIDSeed } from 'react-uid';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import ReactHtmlParser from 'react-html-parser';
import GraphqlBreadcrumbs from '../../GraphqlCommonComponents/GraphqlBreadcrumbs/GraphqlBreadcrumbs';
import GraphqlSeoHeadTags from '../../GraphqlCommonComponents/GraphqlSeoHeadTags/GraphqlSeoHeadTags';
import mockData from './ViewData';

const styles = (theme)  => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: theme.palette.tiers.container.maxWidth.downSmall,
            margin: '0 auto',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: theme.palette.tiers.container.maxWidth.downMedium,
        },
        [theme.breakpoints.up('md')]: {
            width: '100%',
            maxWidth: '980px',
            margin: '0 auto',
        },
        [theme.breakpoints.up('lg')]: {
            width: '100%',
            maxWidth: '980px',
            margin: '0 auto',
        },
    },
    imoc: {
        background: theme.palette.birthdayFlowerBgColor,
        color: theme.palette.common.white,
        marginBottom: 10,
        '& p': {
            paddingLeft: 20,
            fontSize: 14,
            paddingRight: 50,
            [theme.breakpoints.down('sm')]: {
                padding: '0 10px',
            },
        },
    },
    imocTitle: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingRight: 10,
        borderRight: '1px solid',
        margin: '25px 0',
        [theme.breakpoints.down('sm')]: {
            margin: 0,
            justifyContent: 'center',
        },
        '& h1': {
            margin: 0,
            fontWeight: 'normal',
            fontSize: 40,
            [theme.breakpoints.down('sm')]: {
                fontSize: 30,
            },
        },
    },
    content: {
        margin: 10,
        background: theme.palette.common.white,
        border: `2px solid ${theme.palette.ShrBordercolor}`,
        '& img': {
            width: '100%',
            display: 'block',
        },
        '& h2': {
            textAlign: 'center',
            color: theme.palette.common.black,
            border: 'none',
            fontWeight: 'normal',
            fontSize: 22,
        },
        '& p': {
            margin: '10px 15px 25px',
            fontSize: 12,
            overflow: 'hidden',
        },
        '& span': {
            margin: '10px auto',
            textAlign: 'center',
            display: 'block',
            fontSize: 12,
            cursor: 'pointer',
            textDecoration: 'underline',
            fontWeight: 'bold',
        },
    },
    link: {
        textAlign: 'center',
        margin: 30,
        '& a': {
            textDecoration: 'none',
            color: theme.palette.common.white,
            background: theme.palette.birthdayFlowerBgColor,
            padding: '10px 15px',
            textTransform: 'uppercase',
            fontSize: 12,
        },
    },
    bottomContainer: {
        margin: '0 10px',
        '& h2': {
            width: '100%',
            fontSize: 16,
            color: theme.palette.cms?.primary || theme.palette.colorPrimary,
        },
        '& ul': {
            fontSize: 12,
        },
        '& p': {
            margin: 0,
            fontSize: 12,
        },
    },
    contentMain: {
        maxHeight: 530,
        zIndex: '999999999999',
    },
    backToTop: {
        color: theme.palette.cms?.primary || theme.palette.colorPrimary,
        cursor: 'pointer',
        textDecoration: 'underline',
        fontSize: 14,
    },
    breadcrumb: {
        margin: '0 10px',
    },
    imocContainer: {
        margin: '0 10px',
    },
});
const scrollTop = () => {
    if (typeof window !== 'undefined') {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }
};
const SeoBirthFlowersBlock = ({ classes, data }) => {
    const {
        seo = {},
    } = data;
    const {
        imoc_title, imoc_des, products, birtday_flower,  about_birthday_flower,
    } = mockData;
    const seed = useUIDSeed();
    const [readData, setShowLongDescription] = useState({ showLongDescription: null });
    const { showLongDescription } = readData;
    return (
        <>
            {
                seo?.seo_searchable
                    ? (
                        <GraphqlSeoHeadTags seoData={seo} />
                    ) : null
            }
            <div className={classes.container}>
                <div className={classes.breadcrumb}>
                    <GraphqlBreadcrumbs breadCrumbArray={seo?.breadcrumb} />
                </div>
                <div className={classes.imocContainer}>
                    <Grid container className={classes.imoc}>
                        <Grid item xs={12} sm={6} md={5} lg={5} className={classes.imocTitle}>
                            <h1>
                                {imoc_title}
                            </h1>
                        </Grid>
                        <Grid item xs={12} sm={6} md={7} lg={7}>
                            {ReactHtmlParser(imoc_des)}
                        </Grid>
                    </Grid>
                </div>
                <Grid container>
                    {products.map((list, i) => {
                        const shopBtnLabelText = ` ${  list?.title.replace(/<br>/ig, '')}`;
                        return (
                            <Grid key={seed(list)} xs={12} sm={6} md={4} lg={4} item className={showLongDescription  ===  `panel_${i}` ? classes.contentMain : ''}>
                                <div className={classes.content}>
                                    <img src={list.image_path} alt="Product" />
                                    <h2 data-testId="productName">{ReactHtmlParser(list.title)}</h2>
                                    {showLongDescription  === `panel_${i}`
                                        ? ReactHtmlParser(list.long_des)
                                        : ReactHtmlParser(list.short_des)}
                                    {showLongDescription  ===  `panel_${i}`
                                        ? (
                                            <span
                                                role="presentation"
                                                type="button"
                                                onClick={() => setShowLongDescription({ showLongDescription: null   })}
                                            >Read Less -
                                            </span>
                                        )
                                        : (
                                            <span
                                                role="presentation"
                                                type="button"
                                                onClick={() => setShowLongDescription({
                                                    showLongDescription: `panel_${i}`,
                                                })}
                                            >Read More +
                                            </span>
                                        )}
                                    <div className={classes.link}><Link to={list.link} aria-label={`Shop Now${shopBtnLabelText} button`}>Shop Now</Link></div>
                                </div>
                            </Grid>
                        );
                    },

                    )}
                    <Grid container>
                        <div className={classes.bottomContainer}>
                            <h2>About Birth Month Flowers</h2>
                            {ReactHtmlParser(about_birthday_flower)}
                            <h2>What is my birth flower?</h2>
                            {ReactHtmlParser(birtday_flower)}
                            <span className={classes.backToTop} role="presentation" onClick={() => scrollTop()}>
                                Back to top
                            </span>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

SeoBirthFlowersBlock.propTypes = {
    classes: object.isRequired,
    data: shape({
        seo: shape({
            seo_searchable: bool.isRequired,
            page_title: string.isRequired,
            keywords: string.isRequired,
            meta_description: string.isRequired,
        }).isRequired,
    }).isRequired,

};
export default withStyles(styles)(SeoBirthFlowersBlock);
