/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    object, shape, string, arrayOf,
} from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import { useUIDSeed } from 'react-uid';

const useStyles = makeStyles((theme) => ({
    article: {
        padding: 8,
        '& img': {
            width: '100%',
        },
        '& a': {
            fontSize: 14,
            textAlign: 'center',
            textDecoration: 'none',
            color: theme?.palette?.common?.black,
        },
        '& p': {
            margin: 0,
        },
    },
    articleHeader: {
        '& h3': {
            textAlign: 'center',
            fontSize: 20,
            '&::before': {
                width: '13%',
                right: '0.5em',
                height: '1px',
                content: '""',
                display: 'inline-block',
                position: 'relative',
                marginLeft: '-50%',
                verticalAlign: 'middle',
                backgroundColor: theme?.palette?.grey8,
            },
            '&::after': {
                left: '.5em',
                width: '13%',
                height: '1px',
                content: '""',
                display: 'inline-block',
                position: 'relative',
                marginRight: '-50%',
                verticalAlign: 'middle',
                backgroundColor: theme?.palette?.grey8,
            },
        },
    },
}));

const returnLink = (item) => {
    if (!item) {
        return null;
    }
    const href = item.link?.href || '';
    const title = item.link?.title || '';
    const url = item.image?.url || '';
    return (
        href.indexOf('http://') === 0 || href.indexOf('https://') === 0
            ? (
                <a href={href}>
                    <img src={url} alt={title || 'Related Articles'} />
                    <p>{title}</p>
                </a>
            )
            : (
                <Link to={href}>
                    <img src={url} alt={title || 'Related Articles'} />
                    <p>{title}</p>
                </Link>
            )
    );
};

const SeoRelatedArticlesBlock = ({ data, articleClasses }) => {
    const seed = useUIDSeed();
    const classes = useStyles();
    const  { articles = [], header = '' } = data;
    return (
        <Grid container className={articleClasses}>
            <Grid className={classes.articleHeader} item xs={12}>
                <h3>{header}</h3>
            </Grid>
            {
                articles.map((article) => (
                    <Grid key={seed(article)} item xs={6} sm={3} md={3} lg={3}>
                        <div className={classes.article}>
                            {returnLink(article)}
                        </div>
                    </Grid>
                ))
            }
        </Grid>
    );
};

SeoRelatedArticlesBlock.propTypes = {
    articleClasses: string,
    data: shape({
        header: string.isRequired,
        articles: arrayOf(shape({
            image: object.isRequired,
            link: object.isRequired,
        })).isRequired,
    }).isRequired,
};
SeoRelatedArticlesBlock.defaultProps = {
    articleClasses: '',
};
export default SeoRelatedArticlesBlock;
