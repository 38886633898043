/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/*
 * Using this json only for 18F brand.
 */

const data = {
    imoc_title: 'Birth Month Flowers',
    imoc_des: ' <p>Just as each month has its own gemstone, each month also has its own traditional birth flower, appropriate for the time and season. Learn more about each birth flower by selecting a month below and order <a href="/birthday-flowers-10359" title="Birthday Flowers">birthday flowers</a> for yourself or a loved one!</p>',
    products: [
        {
            image_path: 'https://cdn1.1800flowers.com/wcsstore/Flowers/images/2016/birth-flowers/january-birth-flower-carnation.jpg',
            title: 'January Birth Flower <br>Carnation',
            short_des: '<p>The carnation is among the oldest flowers to be cultivated, mentioned in Greek literature two centuries ago. Some scholars believe that the bloom&apos;s modern name derives from an old word</p>',
            long_des: '<p>The carnation is among the oldest flowers to be cultivated, mentioned in Greek literature two centuries ago. Some scholars believe that the bloom&apos;s modern name derives from an old word for flower garlands, as carnations were used in ancient Greece to make ceremonial crowns.'
        + '<br><br>'
        + 'The birth flower for January, the carnation is for many a symbol of joy, generally speaking. In additional, light red carnations represent admiration, while dark red flowers express deep love and affection. White carnations indicate pure love and good luck, and pink carnations have become symbolic of a mother’s undying love.'
        + '<br><br>'
        + 'Carnations are available year-round in a wide variety of colors and sizes. This diversity and availability has allowed the carnation to become one of the most popular flowers, as well as one of the longest-lasting. If handled properly, cut carnations can live for a whole month. It is advised that carnations be re-cut under water and that any extraneous foliage be removed before adding the flower stems to a vase or design.</p>',
            link: '/carnations',
        },
        {
            image_path: 'https://cdn1.1800flowers.com/wcsstore/Flowers/images/2016/birth-flowers/february-birth-flower-violet.jpg',
            title: 'February Birth Flower <br>Violet',
            short_des: '<p>The birth flower for February is associated with faithfulness, truth and affection. Ancient Greeks believed the violet to be the flower of Aphrodite, goddess of love and beauty. (The</p>',
            long_des: '<p>The birth flower for February is associated with faithfulness, truth and affection. Ancient Greeks believed the violet to be the flower of Aphrodite, goddess of love and beauty. (The bloom&apos;s other reputation, as a classic symbol of modesty is the source of the expression "shrinking violet.") '
            + '<br><br>'
            + 'The sweet, gentle fragrance of the violet has remained popular for hundreds of years. Indeed, its fragrance affects one&apos;s sense of smell so profoundly that the effects of unpleasant odors are weakened. Violets have myriad medicinal and culinary uses as well.'
            + '<br><br>'
            + 'If cared for properly, violet plants will flower without interruption for years. To keep your plant looking full and fresh, the floral experts at 1-800-Flowers.com recommend that you not expose your plant to temperatures below 60° or above 80° F. Place the plant in windows with bright light, but not in direct sunlight. You should always keep a violet plant&apos;s soil moist, but allow slight drying between waterings. (Not to worry, though, if you mess this up the first time, as violet plants re-bloom easily in the home.)</p>',
            link: '/purple',
        },
        {
            image_path: 'https://cdn1.1800flowers.com/wcsstore/Flowers/images/2016/birth-flowers/march-birth-flower-daffodil.jpg',
            title: 'March Birth Flower <br>Daffodil',
            short_des: '<p>The daffodil-or daffadowndilly, narcissus or jonquil-is a flower with as many different meanings as names. Most commonly known as a classic symbol of spring, the birth flower for</p>',
            long_des: '<p>The daffodil-or daffadowndilly, narcissus or jonquil-is a flower with as many different meanings as names. Most commonly known as a classic symbol of spring, the birth flower for March is also a symbol of hope. And because of its bright yellow color, the daffodil is representative of joy, friendship and sunshine.'
            + '<br><br>'
            + 'The decorative use of daffodils dates as far back as ancient Egyptian tombs and frescoes at Pompeii. They make frequent appearances in classical literature.'
            + '<br><br>'
            + 'Cut daffodils are available from November through April in a wide variety of sizes, shapes and colors. When choosing your flowers, look for bunches of tight buds showing partial color. Flower heads should bend slightly and leaves should be a true green with no yellowing. The floral experts at 1-800-Flowers.com recommend cutting the stems of your daffodils with a sharp knife before putting them in water. They also suggest that you not mix daffodils with other flowers for at least six hours after cutting, as daffodils secrete a sap poisonous to other flowers. Daffodils should last for four to six days, if kept cool in a clean vase.</p>',
            link: '/bulbs',
        },
        {
            image_path: 'https://cdn1.1800flowers.com/wcsstore/Flowers/images/2016/birth-flowers/april-birth-flower-daisy.jpg',
            title: 'April Birth Flower <br>Daisy',
            short_des: '<p>The daisy family is one of the largest in the botanical world, containing more than 1200 particular species of flowers. Many popular garden plants—such as marigolds, dahlias</p>',
            long_des: '<p>The daisy family is one of the largest in the botanical world, containing more than 1200 particular species of flowers. Many popular garden plants—such as marigolds, dahlias and sunflowers —are part of the daisy family. Meanwhile, several flowers we call daisies are actually chrysanthemums or asters.'
            + '<br><br>'
            + 'There are many different varieties of the true daisy; the most popular of these are the black-eyed Susan, Shasta daisy and Gerbera daisy. Gerbera daisies, also known as African daisies, have become very popular in recent years. These cheerful flowers can be recognized by their large-disc shaped head and long, fuzzy stem. Also, Gerbera daisies are available in a vast spectrum of colors. There are more than 250 varieties of this daisy, the birth flower for April, most often found in vibrant hues, including red, pink, orange, yellow and white. The Gerbera daisy has become a modern symbol of summer.'
            + '<br><br>'
            + 'ccording to the floral experts at 1-800-Flowers.com, daisies should last approximately five to seven days if properly cared for. Keep cut flowers in a cool location, away from drafts and heat sources. To keep your daisies looking fresh, you should re-cut stems and add warm water and plant food to their vase every other day. (Keep in mind that marguerites, the daisies most often grown in American gardens, will make other flowers wilt if placed together in one vase.)</p>',
            link: '/daisies',
        },
        {
            image_path: 'https://cdn1.1800flowers.com/wcsstore/Flowers/images/2016/birth-flowers/may-birth-flower-lily-of-the-valley.jpg',
            title: 'May Birth Flower <br>Lily of the Valley',
            short_des: '<p>The lily of the valley (indeed, sometimes called the "May lily") is famous for its small, white, bell-shaped flowers and delicate fragrance. Its beautiful signature scent has inspired countless</p>',
            long_des: '<p>The lily of the valley (indeed, sometimes called the "May lily") is famous for its small, white, bell-shaped flowers and delicate fragrance. Its beautiful signature scent has inspired countless perfumes and beauty products. The birth flower for May is primarily available in white, but it can also be found in pink and lilac.'
            + '<br><br>'
            + 'While the lily of the valley is most popular as a perennial garden plant, it has experienced a recent surge in popularity as a cut flower, most often in bridal bouquets. The flower&apos;s traditional role as a symbol of purity, humility and virtue adds to its popularity in wedding arrangements.'
            + '<br><br>'
            + 'With proper care, cut lily stems should last approximately five to seven days. The floral experts at 1-800-Flowers.com recommend placing the cut blooms in a vase with lukewarm water and floral preservative. Re-cut the stems and add warm water and preservative to the vase every other day to keep your May lilies looking fresh. Once the stems dry out, the flowers will lose their fragrance and take on a brownish-yellow hue.</p>',
            link: '/Lilies',
        },
        {
            image_path: 'https://cdn1.1800flowers.com/wcsstore/Flowers/images/2016/birth-flowers/june-birth-flower-roses.jpg',
            title: 'June Birth Flower <br>Roses',
            short_des: '<p>Roses have symbolized love, friendship and beauty across cultures immemorially, making them the most popular of all cut flowers. While red roses are almost always the bestselling of </p>',
            long_des: '<p>Roses have symbolized love, friendship and beauty across cultures immemorially, making them the most popular of all cut flowers. While red roses are almost always the bestselling of these blooms, yellow roses is the second most purchased color, with peach, pink and white following respectively.'
            + '<br><br>'
            + 'Roses have long been regarded for their ability to warm someone&apos;s heart or brighten someone&apos;s day, but their scent has recently been found to induce relaxation and a sense of calm. It has been proven that "stopping to smell the roses" can literally relieve stress, treat insomnia and calm nerves.'
            + '<br><br>'
            + 'The next time you have the birth flower for June in the home extend your enjoyment and relaxation by drying them— which will increase their natural life by one to two years. The floral experts at 1-800-Flowers.com recommend air-drying as the easiest way to preserve your roses. You&apos;ll need a warm, low-moisture room that has plenty of circulating air. The flowers can be dried individually or in small bunches. Make sure that they are bunched loosely and there is enough air circulating around each of the stems. When the stems are dry and rigid, the roses are ready to be stored or used in arrangements, wreaths, or door swags or as potpourri.</p>',
            link: '/roses',
        },
        {
            image_path: 'https://cdn1.1800flowers.com/wcsstore/Flowers/images/2016/birth-flowers/july-birth-flower-larkspur.jpg',
            title: 'July Birth Flower <br>Larkspur',
            short_des: '<p>Larkspur is symbolic of a beautiful spirit, lightness and swiftness. In the language of flowers, the meaning of the larkspur is fickleness and levity....especially the pink variety. Although</p>',
            long_des: '<p>Larkspur is symbolic of a beautiful spirit, lightness and swiftness. In the language of flowers, the meaning of the larkspur is fickleness and levity....especially the pink variety. Although closely related and often confused, larkspur and delphinium are not the same—larkspur&apos;s more delicate foliage differentiates it from its cousin flower.'
            + '<br><br>'
            + 'Colors of the birth flower for July vary and include purple, blue, red, yellow and white. The bloom&apos;s petals grow together forming a spur at the end (that gives the flower its name). The larkspur was also called "Lark&apos;s Heel" by Shakespeare, and it has been referred to as well as "Lark&apos;s Claw" and "Knight&apos;s Spur." '
            + '<br><br>'
            + 'With proper care, cut larkspur should last approximately five to seven days. The floral experts at 1-800-Flowers.com recommend placing the cut flowers in a vase with lukewarm water and floral preservative. Re-cut the stems and add warm water and preservative to the vase every other day to keep flowers looking fresh. You can also air-dry larkspur for use in arrangements, wreaths, or door swags or as potpourri.</p>',
            link: '/summer-dunes-90816',
        },
        {
            image_path: 'https://cdn1.1800flowers.com/wcsstore/Flowers/images/2016/birth-flowers/august-birth-flower-gladiolus.jpg',
            title: 'August Birth Flower <br>The Gladiolus',
            short_des: '<p>The gladiolus symbolizes remembrance and strength of character. Its name is derived from the Latin word for "little sword," on account of the shape of its leaves. (Indeed, the birth flower for</p>',
            long_des: '<p>The gladiolus symbolizes remembrance and strength of character. Its name is derived from the Latin word for "little sword," on account of the shape of its leaves. (Indeed, the birth flower for August is also known as the "Sword lily.")'
            + '<br><br>'
            + 'A member of the iris family, the gladiolus is one of the most popular cut flowers worldwide, with more than 250 species in existence. The gladiolus is distinguished by a thick stem covered with flower buds that open from the bottom up. These buds will differ in color and blossom size, according to their species.'
            + '<br><br>'
            + 'Gladiolus stems add a bold and dramatic touch to any flower arrangement. With proper care, the flowers should last about seven to ten days. The floral experts at 1-800-Flowers.com recommend selecting stems with one to five blossoms showing color. Cut the stems on an angle before placing in a container full of water and floral preservative. Also, snipping the top two or three green buds off the gladiolus will straighten out the stem and help the flowers below to open up. Display gladioli in a cool location, away from sources of heat or cold.</p>',
            link: '/birthday-flowers-10359',
        },
        {
            image_path: 'https://cdn1.1800flowers.com/wcsstore/Flowers/images/2016/birth-flowers/september-birth-flower-aster.jpg',
            title: 'September Birth Flower <br>Aster',
            short_des: '<p>The name of this bloom comes from the Greek word for "star." The aster is unique for its delicate purple color, although it can also be found in other colors, including blue and white. The birth</p>',
            long_des: '<p>The name of this bloom comes from the Greek word for "star." The aster is unique for its delicate purple color, although it can also be found in other colors, including blue and white. The birth flower for September symbolize love, daintiness and good luck.'
            + '<br><br>'
            + 'Ancient peoples believed that the odor of the leaves of the aster, when burnt, drove away serpents. In much of the world, asters symbolize afterthoughts and wishes that things had turned out differently....except in China, where they signify fidelity.'
            + '<br><br>'
           + ' To keep your asters looking beautiful longer, the floral experts at 1-800-Flowers.com recommend putting them in a clean vase with fresh, room-temperature water as soon as possible. To extend the life of your cut asters, mix two teaspoons of sugar or lemonade in new water and place your arrangement away from direct sunlight. Avoid humid or drafty areas.</p>',
            link: '/florist-delivered-mixed-floral-145677',
        },
        {
            image_path: 'https://cdn1.1800flowers.com/wcsstore/Flowers/images/2016/birth-flowers/october-birth-flower-calendula.jpg',
            title: 'October Birth Flower <br>Calendula',
            short_des: '<p>The calendula&apos;s name is derived from the Latin word for calendar, due to its lengthy flowering season. This popular variety of marigold blooms consistently from the early spring until first frost.</p>',
            long_des: '<p>The calendula&apos;s name is derived from the Latin word for calendar, due to its lengthy flowering season. This popular variety of marigold blooms consistently from the early spring until first frost.'
            + '<br><br>'
            + 'The birth flower for October serves a multitude of culinary, medicinal, cosmetic and religious purposes. It may be most well known for its exceptional skin-healing properties; considered soothing, softening and healing for many skin conditions, the calendula has been harvested since the Middle Ages. In addition, the flower&apos;s golden hue allows it to substitute for saffron by cooks coloring cakes, butter, broths, and puddings.'
            + '<br><br>'
            + 'These cut flowers should last approximately four to five days. Alternatively, calendula stems dry very nicely. To preserve your flowers, the floral experts at 1-800-Flowers.com recommend hanging the cut flowers upside down in bunches of 3 or 4 stems. Store in a cool, dry location out of sunlight with good ventilation. These dried flowers can stand alone or be mixed into many arrangements.</p>',
            link: '/birthday-flowers-10359',
        },
        {
            image_path: 'https://cdn1.1800flowers.com/wcsstore/Flowers/images/2016/birth-flowers/november-birth-flower-chrysanthemum.jpg',
            title: 'November Birth Flower <br>Chrysanthemum',
            short_des: '<p>Take the Greek word for gold (“chrysos”) and add it to the word for flower ("anthos") and you get the name for the birth flower for November traditionally a symbol of wisdom and nobility.</p>',
            long_des: '<p>Take the Greek word for gold (“chrysos”) and add it to the word for flower ("anthos") and you get the name for the birth flower for November traditionally a symbol of wisdom and nobility. Today, the chrysanthemum is often associated with optimism, hope and long life.'
            + '<br><br>'
            + 'The most recognizable varieties of chrysanthemum are the pompon, football and daisy flower types. Actually, many flowers we call daisies are technically chrysanthemums. These flowers&apos; colors echo those of crisp fall leaves—deep bronze, pale yellow, rich burgundy—making them a timeless way to celebrate autumn.'
            + '<br><br>'
            + 'According to the flower experts at 1-800-Flowers.com, chrysanthemums must be handled carefully to avoid shattering. However, with proper care and conditioning, you can expect your flowers to last between 7 and 14 days. And because the chrysanthemum is available in many shapes and sizes, it lends itself to a wide variety of arrangement styles.</p>',
            link: '/mums',
        },
        {
            image_path: 'https://cdn1.1800flowers.com/wcsstore/Flowers/images/2016/birth-flowers/december-birth-flower-narcissus.jpg',
            title: 'December Birth Flower <br>Narcissus',
            short_des: '<p>The narcissus takes its name from an ancient Greek myth of self-adulation and unrequited love. In modern times, however, the narcissus has come to be a symbol of youth, beauty and good</p>',
            long_des: '<p>The narcissus takes its name from an ancient Greek myth of self-adulation and unrequited love. In modern times, however, the narcissus has come to be a symbol of youth, beauty and good fortune. Moreover, it is believed that if a narcissus flower is forced to bloom on New Year&apos;s Day, it will bring good luck for the next twelve months.'
            + '<br><br>'
            + 'There are twelve major varieties of the birth flower for December; each possesses its own distinctive fragrance, color combination and shape. Most narcissi display hues of yellow, orange, pink or white.'
            + '<br><br>'
            + 'When cutting narcissi, always use a sharp knife. After being clipped, they will emit a sap that is poisonous to other flowers. The floral experts at 1-800-Flowers.com recommend keeping cut narcissi apart from other flowers; however, if the flowers are to be part of a mixed bouquet, leave narcissi in water for at least 12 hours without being re-cut before adding to an arrangement. Cut narcissi should last for about seven days if kept cool in a vase.</p>',
            link: '/birthday-flowers-10359',
        },
    ],
    birtday_flower: '<ul>'
    + '<li>January Birth Month Flower – Carnation</li>'
    + '<li>February Birth Month Flower – Violet</li>'
    + '<li>March Birth Month Flower – Daffodil</li>'
    + '<li>April Birth Month Flower – Daisy</li>'
    + '<li>May Birth Month Flower – Lily of the Valley</li>'
    + '<li>June Birth Month Flower – Roses</li>'
    + '<li>July Birth Month Flower – Larkspur</li>'
    + '<li>August Birth Month Flower – Gladiolus</li>'
    + '<li>September Birth Month Flower – Aster</li>'
    + '<li>October Birth Month Flower – Calendula</li>'
    + '<li>November Birth Month Flower – Chrysanthemum</li>'
    + '<li>December Birth Month Flower – Narcissus</li>'
    + '</ul>',
    about_birthday_flower: '<p>Everyone has a birth month flower that represents the month you were born, just like everyone has a birthstone for the month they were born in, or a zodiac sign that shows us the specific characteristics or qualities we possess!</p>'
    + '<p>Just like astrology signs, every birth flower has its own meaning, which can be used to describe the special birthday boy or girl. If you’re wondering what flower is your birthday month flower, read our descriptions and shop the collections. Whether your birth month flower is the rose (symbolizing love, friendship &amp; beauty), the carnation (symbolizing joy), or any of the other birth month flowers, it is fun to learn about the flower that represents you and your loved ones! It also can add a special touch to your <a href="/birthday-flowers-10359" title="Birthday Flowers">birthday flowers</a>, to add their birth month flower in their birthday bouquet this year.</p>',
};
export default data;
