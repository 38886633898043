/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    object,  string, shape, arrayOf, bool,
} from 'prop-types';
import Grid from '@material-ui/core/Grid';
import ReactMarkdown from 'react-markdown';
import GraphqlBreadcrumbs from '../../GraphqlCommonComponents/GraphqlBreadcrumbs/GraphqlBreadcrumbs';
import GraphqlSeoHeadTags from '../../GraphqlCommonComponents/GraphqlSeoHeadTags/GraphqlSeoHeadTags';
import isDesktopMobileTablet from '../../../../helpers/DesktopMobile/isDesktopMobileTablet';

const styles = (theme)  => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: theme.palette.tiers.container.maxWidth.downSmall,
            margin: '0 auto',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: theme.palette.tiers.container.maxWidth.downMedium,
            padding: '15px',
        },
        [theme.breakpoints.up('md')]: {
            width: '100%',
            padding: '15px',
        },
        [theme.breakpoints.up('lg')]: {
            width: '100%',
            padding: '15px',
        },
    },
    collapseTitle: {
        textAlign: 'center',
        cursor: 'pointer',
        fontWeight: 'bold',
    },
    plusIcon: {
        height: 24,
        verticalAlign: 'middle',
    },
    collapseBody: {
        '& p': {
            paddingLeft: 40,
            fontSize: 14,
        },
    },
    overview: {
        '& p': {
            fontSize: 14,
        },
        '& strong': {
            fontSize: 16,
        },
    },
    universalPicture: {
        '& ol': {
            paddingLeft: 80,
            fontSize: 14,
        },
    },
    universalArticleBody: {
        '& p': {
            paddingLeft: 40,
            fontSize: 14,
            '& img': {
                [theme.breakpoints.down('sm')]: {
                    width: '100%',
                },
            },
        },
        '& p:nth-child(2)': {
            paddingLeft: 0,
            fontSize: 16,
        },
        '& p:last-child': {
            textAlign: 'center',
            paddingLeft: 0,
        },
    },
    header: {
        margin: '0px 0px 10px',
        textAlign: 'center',
        fontSize: '1.5rem',
        fontWeight: 'bold',
    },
    heroImg: {
        width: '100%',
    },
    breadcrumb: {
        marginBottom: 10,
        paddingLeft: 30,
    },
});
const getDevice = () => {
    if (typeof navigator !== 'undefined') {
        const navigatorUserAgent = navigator.userAgent;
        return isDesktopMobileTablet(navigatorUserAgent);
    }
    return 'desktop'; // return desktop by default
};
const SeoUniversalPicturesInfoBlock = ({
    classes, data,
}) => {
    const [isExpanded, setExpanded] = useState(true);
    const {
        article_header = '', article_body = '', hero = {},   seo = {}, collapsible_content = {}, overview = '',
    } = data;
    const imageUrl =  getDevice() === 'desktop' ? hero?.hero_image_desktop?.url : hero?.hero_image_mobile?.url;
    const title = hero?.title || '';
    const filename = 'Hero Banner';
    const collapsibleTitle = collapsible_content?.title;
    const copy = collapsible_content?.copy;
    return (
        <>
            {
                seo?.seo_searchable
                    ? (
                        <GraphqlSeoHeadTags seoData={seo} />
                    ) : null
            }
            <div className={classes.container}>
                <div className={classes.breadcrumb}>
                    <GraphqlBreadcrumbs breadCrumbArray={seo?.breadcrumb} />
                </div>
                <Grid className={classes.universalPicture}>
                    <Grid container className={classes.contentWrapper}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <h1 className={classes.header}>{title || article_header}</h1>
                            { imageUrl && <div className={classes.imageBlock}><img className={classes.heroImg} src={imageUrl} alt={filename} /></div>}
                            <ReactMarkdown
                                source={overview}
                                className={classes.overview}
                            />
                            <div role="presentation" className={classes.collapseTitle} onClick={() => { setExpanded(!isExpanded); }}>{collapsibleTitle}
                                {isExpanded
                                    ? (
                                        <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" focusable="false" className={classes.plusIcon}>
                                            <g>
                                                <path style={{ fill: '#373737' }} d="M19,13H5V11H19V13Z" />
                                            </g>
                                        </svg>
                                    ) : (
                                        <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" focusable="false" className={classes.plusIcon}>
                                            <g>
                                                <path style={{ fill: '#373737' }} d="M11 3 h2 v7 h7 v2 h-7 v7 h-2 v-7 h-7 v-2 h7 z" />
                                            </g>
                                        </svg>
                                    )}
                            </div>
                            {
                                isExpanded && (
                                    <ReactMarkdown
                                        source={copy}
                                        className={classes.collapseBody}
                                    />
                                )
                            }
                            <ReactMarkdown
                                source={article_body}
                                className={classes.universalArticleBody}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </>
    );
};
SeoUniversalPicturesInfoBlock.propTypes = {
    classes: object.isRequired,
    data: shape({
        article_header: string.isRequired,
        article_body: string.isRequired,
        overview: string.isRequired,
        hero: shape({
            hero_image_desktop: shape({
                url: string.isRequired,
                filename: string.isRequired,
            }),
        }).isRequired,
        collapsible_content: shape({
            copy: string.isRequired,
            title: string.isRequired,
        }),
        seo: shape({
            breadcrumb: arrayOf(
                shape({
                    title: string.isRequired,
                    href: string.isRequired,
                }).isRequired,
            ),
            keywords: string,
            meta_description: string,
            seo_searchable: bool.isRequired,
            page_title: string,
        }).isRequired,
    }).isRequired,
};
export default withStyles(styles)(SeoUniversalPicturesInfoBlock);
