/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    object, shape, string, arrayOf,
} from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { useUIDSeed } from 'react-uid';
import Media from 'react-media';
import LinkOrATag from '../../GraphqlCommonComponents/LinkOrATag/LinkOrATag';

const styles = (theme)  => ({
    containter: {
        width: '20%',
        float: 'left',
        padding: '5px',
        textAlign: 'left',
        margin: '10px 0 0 25px',
    },
    leftNav: {
        padding: '0px 40px',
        '& ul': {
            padding: 0,
            listStyle: 'none',
            margin: 0,
            '& li': {
                margin: '10px 0',
            },
            '& a': {
                color: theme?.palette?.grey3,
                textDecoration: 'none',
                fontSize: 14,
            },
        },
    },
    aboutUsLeftNav: {
        '& li': {
            margin: '10px 0 5px',
            borderBottom: theme.palette.borderBottom,
        },
    },
});

const linkRender = (href, title) => {
    if (href) {
        return (
            <LinkOrATag href={href}>
                {title}
            </LinkOrATag>
        );
    }
    return  title;
};
const SeoLeftRailBlock = ({ classes, data, pageUrl }) => {
    const seed = useUIDSeed();
    const  loadCssUrlBase = () => {
        switch (pageUrl) {
            case '/about-us-company-overview':
                return classes.aboutUsLeftNav;
            case '/apple-business-chat':
                return classes.appleChatLeftNav;
            case '/passport-terms-and-conditions':
                return classes.passportLeftNav;
            default:
                return null;
        }
    };
    return (
        <Media
            query="(min-width: 599px)"
        >
            {(matches) => (matches
                ? (
                    <Grid>
                        {data?.map((rail) => (
                            <Grid key={seed(rail)}>
                                <div className={`${classes.leftNav} ${loadCssUrlBase()}`}>
                                    <h4>{rail?.header}</h4>
                                    <ul>
                                        {rail?.link?.map((list) => {
                                            const title = list?.title || '';
                                            const href = list?.href || '';
                                            return (<li key={seed(list)}>{linkRender(href, title)}</li>);
                                        })}
                                    </ul>
                                </div>
                            </Grid>
                        ))}
                    </Grid>
                ) : null
            )}
        </Media>
    );
};

SeoLeftRailBlock.propTypes = {
    classes: object.isRequired,
    pageUrl: string.isRequired,
    data: arrayOf(shape({
        header: string.isRequired,
        link: arrayOf(shape({
            title: string.isRequired,
            href: string.isRequired,
        })).isRequired,
    })).isRequired,
};
export default withStyles(styles)(SeoLeftRailBlock);
